import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import SEO from "../../components/seo.js"
import Loader from '../../components/WidgetComponents/Loader'
import Layout from "../../components/layout"
import * as typeformEmbed from '@typeform/embed'
import {auth, firestore} from '../../utils/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import {getUserFirstName, getUserLastName} from '../../utils/general';

// import WaitlistAppBar from '../components/WidgetComponents/WaitlistAppBar.js'

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function Page(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [selected, setSelected] = useState(null)

  function addUseCase(userID) {

    let docRef = firestore
      .collection("users")
      .doc(userID);

    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          firestore
            .collection("users")
            .doc(userID)
            .update({useCase: selected});
          window
            .analytics
            .identify(userID, {use_case: selected});
          navigate('/join/website', {
            state: {
              selection: selected
            }
          })
        } else {
          console.log('No User')
          // navigate('/')
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  }

  function openForm() {
    typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
      // mode: 'drawer_left',
      autoOpen: true,
      // autoClose: 3,
      hideScrollbars: true,
      onSubmit: function() {
        console.log('Typeform successfully submitted')
      }
    })
  }

  function handleSignOut() {
    // firebase
    //   .auth()
    //   .signOut();
    navigate('/')
  }

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        // setInnerHeight(window.innerHeight)
        setIsLoaded(true)
      }
    }
  }, [isLoaded]);

  let buttonStyle = {
    margin: '10px',
    textTransform: "none",
    fontSize: '20px',
    width: '250px'
  }

  let selectedButtonStyle = {
    margin: '10px',
    textTransform: "none",
    fontSize: '20px',
    width: '250px',
    backgroundColor: '#d4ffe0',
    // color: '#00964b'
  }

  return (<Layout>
    <SEO title='Get Early Access'/>
    <Fade in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          margin: '0px',
          minHeight: '100vh',
          // width: '100vw',
          padding: '50px 0px'
        }}>
        <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
            backgroundColor: '',
            // height: '100vh',
            // width: '70vw',
            padding: '0px 10px'
          }}>
          <Typography style={{
              fontFamily: 'Work Sans',
              fontWeight: '300',
              fontSize: '20px',
              // padding: '15px 20px 30px',
              padding: '0px',
              // marginBottom: '-40px',
              // position: 'relative',
              color: 'grey'
            }}>Get Early Access</Typography>
          <Grid container="container" direction="column" justify="space-evenly" alignItems="center" style={{
              backgroundColor: '',
              margin: 'auto',
              // marginTop: '-20px'
              paddingBottom: '28px'
            }}>
            <Typography style={{
                fontFamily: 'Work Sans',
                fontSize: '35px',
                fontWeight: 'bolder',
                textAlign: 'center',
                lineHeight: 1.1,
                padding: '20px 10px 35px'
                // height: '40px',
                // padding: '30px 50px 50px 50px'
              }}>{"What will you be using QuickStyle for?"}</Typography>
            <Button onClick={() => setSelected('startup')} size='large' variant='outlined' color='' style={(
                selected == 'startup')
                ? selectedButtonStyle
                : buttonStyle}>Startup</Button>
            <Button onClick={() => setSelected('personal brand')} size='large' variant='outlined' color='' style={(
                selected == 'personal brand')
                ? selectedButtonStyle
                : buttonStyle}>Personal Brand</Button>
            <Button onClick={() => setSelected('small business')} size='large' variant='outlined' color='' style={(
                selected == 'small business')
                ? selectedButtonStyle
                : buttonStyle}>Small Business</Button>
            <Button onClick={() => setSelected('side project')} size='large' variant='outlined' color='' style={(
                selected == 'side project')
                ? selectedButtonStyle
                : buttonStyle}>Side Project</Button>
            <Button onClick={() => setSelected('other')} size='large' variant='outlined' color='' style={(
                selected == 'other')
                ? selectedButtonStyle
                : buttonStyle}>Other</Button>
          </Grid>
        </Grid>
        <Grid container="container" direction="row" wrap="wrap-reverse" justify="center" alignItems="center" style={{
            backgroundColor: '',
            height: '60px'
          }}>
          {
            (selected !== null) && <Fade in="in">
              <Button color='secondary' variant='contained' size='large' onClick={() => addUseCase(props.userID)} style={{
                  fontSize: '16px',
                  // textTransform: 'None'
                }}>Let's Get Started</Button>
            </Fade>
          }
          </Grid>
      </Grid>
    </Fade>
  </Layout>)
}

export default function Home({
  location
}, props) {
  const [authUser, authLoading, authError] = useAuthState(auth);

  if (authLoading) {
    console.log('Authentication Loading')
    return (<Loader/>);
  }
  if (authError) {
    console.log('Authentication Error')
    navigate('/')
    return <Loader/>
  }
  if (authUser) {
    console.log('User Authenticated')
    window
      .analytics
      .identify(authUser.uid, {
        // name: authUser.displayName,
        // email: authUser.email
      });
    return (<Layout>
      <SEO title="My Dashboard"/>
      <Page userID={authUser.uid}/>
    </Layout>);
  }
  console.log('No User Authenticated')
  navigate('/')
  return <Loader/>
}

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
